import request from "@/utils/request";

/**
 * Get all activities with limit and search category features.
 *
 * @param {object} params
 * @param {string} [params.category] - search activity category
 * @param {number} [params.limit]
 */
export function getActivities(params) {
  return request({
    url: "api/v1/activities",
    method: "GET",
    params,
  });
}

/**
 * Get single activity.
 *
 * @param {string} activity_id
 */
export function getActivity(activity_id) {
  return request({
    url: `api/v1/activities/${activity_id}`,
    method: "GET",
  });
}
