<template lang="pug">
.maincontain
  #activity.no-scroll-bar
    .bg.bg--wrap
      .bg
        .bg--sky
          video(playsinline autoplay muted loop)
            source(src='/static/sky2Clip.mov' type="video/mp4")
      img.bg.bg--mask(:src="require(`@/assets/images/mask.svg`)")
    .container.activityBlock
      .row
        .col-12.col-lg-8
          .activity__month
            .activity__text(:class="{'activity__text--active': isSelectOct}" @click="isSelectOct = true; moveToMonth(2021,10)") {{ $t('activity.october') }}
            .activity__text(:class="{'activity__text--active': !isSelectOct}" @click="isSelectOct = false; moveToMonth(2021,11)") {{ $t('activity.november') }}
          .activity__select.d-block.d-lg-none
            .activity__select(:data-value='value' :data-list='iconArray')
              .selector(@click='selectorToggle()' ref="labelSelector")
                .label
                  .activity__icon__circle(:class="value.labelClass")
                  .activity__icon__text {{ value[`text_${localeLang}`] }}
                  img(
                    :src="require(`@/assets/images/sidebar-arrow.svg`)"
                    class="arrow"
                    :class='{ expanded : visible }'
                  )
                div(:class='{ hidden : !visible, visible }')
                  ul
                    li.d-flex.align-items-center(
                      :class='{ current : item === value }'
                      v-for='item in iconArray'
                      @click='select(item)'
                    )
                      .activity__icon__circle(:class="item.labelClass")
                      .activity__icon__text {{item[`text_${localeLang}`]}}

          .activity__label.d-none.d-lg-flex
            .activity__icon(
              v-for="(item,index) in iconArray"
              :key="index"
              @click="onChangeIcon(item)"
              :class="{'activity__icon--active': item.active},{'activity__icon--all': item.name === 'all'}"
            )
              .activity__icon__circle(:class="item.labelClass")
              .activity__icon__text(:class="{'activity__icon__text--all': item.name === 'all'}") {{item[`text_${localeLang}`]}}
          .row
            .col(v-if="attributes")
              v-calendar.custom-calendar(
                ref="calendar"
                :masks='masks'
                :attributes='filteredActivities'
                :first-day-of-week="2"
                min-weeks="1"
                locale="en"
                is-expanded
              )
                template(v-slot:day-content='{ day, attributes }')
                  .date
                    span.day-label {{ getDate(day.date) }}
                    .activity(
                      v-for='attr in attributes'
                      :key='attr.key'
                      @click="isOpenInfo = true; selectActivity(attr)"
                    )
                      .label__time {{ attr.customData.time }}
                      .label__title(:class='attr.customData.class')
                        .label__text.text-truncate {{ attr.customData[`title_${localeLang}`] }}
        .col-12.col-lg-4.pb-6
          .info__block(:class="{'info__block--none': !isOpenInfo}")
            .info(:class="{'info--none': !isOpenInfo}")
              .artist__close(@click="isOpenInfo = false")
              .info__sign(v-if="!isOpenInfo") {{ $t('activity.showMore') }}
              template(v-else)
                //- .a {{selectedActivity}}
                .info__img(:style="{backgroundImage:`url(${$getPhotoUrl(selectedActivity.photo)})`}")
                  img
                .info__title {{selectedActivity[`title_${localeLang}`]}}
                .info__item {{ $t('activity.lecturer') }}｜
                  span(v-for="speaker in selectedActivity.speakers") {{ speaker[`name_${localeLang}`] + " " }}
                .info__item {{ $t('activity.date') }}｜{{ selectedActivity.date }}
                .info__item {{ $t('activity.time') }}｜{{ selectedActivity.start_time }} - {{ selectedActivity.end_time }}
                .flex-center
                  .info__item {{ $t('activity.venue') }}｜{{ selectedActivity[`location_${localeLang}`] }}
                .flex-center
                  a.info__btn.info__btn--left(
                    :href="selectedActivity.registration_url.includes('http')?selectedActivity.registration_url:'https://'+selectedActivity.registration_url"
                    target="_blank"
                  ) {{ $t('activity.booking') }}
                  .info__btn.info__btn--right(v-if="selectedActivity.live") {{ $t('activity.streaming') }}
                .flex-center
                  a.info__btn.info__btn--full(
                    v-if="selectedActivity.final_project_url"
                    :href="selectedActivity.final_project_url.includes('http')?selectedActivity.final_project_url:'https://'+selectedActivity.final_project_url"
                    target="_blank"
                  ) {{ $t('activity.final') }}
                ArtistCollapse.mt-4
                  template(v-slot:title) {{ $t('activity.intro') }}
                  template(v-slot:content) {{selectedActivity[`introduction_${localeLang}`]}}
                ArtistCollapse.mt-4
                  template(v-slot:title) {{ $t('activity.bio') }}
                  template(v-slot:content)
                    MoreInfo(v-for="speaker in selectedActivity.speakers")
                      template(v-slot:moreBlock)
                        .info__artist
                          .d-flex.align-items-center
                            .info__artist__img(v-if="speaker.avatar_url")
                              img(:src="$getPhotoUrl(speaker.avatar_url)")
                            .info__artist__name {{speaker[`name_${localeLang}`]}}
                          .info__artist__text {{speaker[`introduction_${localeLang}`]}}
                    //- MoreInfo
                      template(v-slot:moreBlock)
                        .info__artist
                          .d-flex.align-items-center
                            .info__artist__img
                              img(:src="require(`@/assets/images/artwork-avarta.png`)")
                            .info__artist__name 葉廷皓
                          .info__artist__text TouchDesigner愛好者與重度使用者。近年多關注在滴流視覺效果研究、介面系統設計、Dome
              .info__btn.info__btn--back.d-block.d-lg-none(@click="isOpenInfo = false")
                img(:src="require(`@/assets/images/activity-back.svg`)")
                span {{ $t('activity.all') }}
</template>

<script>
import { Calendar } from "v-calendar";
import { getActivities, getActivity } from "@/api/activity";
import ArtistCollapse from "@/component/ArtistCollapse.vue";
import MoreInfo from "@/component/MoreInfo.vue";
import ArtistModal from "@/component/ArtistModal.vue";
import moment from "moment";

export default {
  name: "activity",
  components: {
    ArtistCollapse,
    MoreInfo,
    ArtistModal,
    Calendar,
  },
  data() {
    // const month = new Date().getMonth();
    // const year = new Date().getFullYear();
    return {
      value: {
        name: "all",
        active: true,
        text_zh: "總覽",
        text_en: "All",
        labelClass: "activity__icon__circle--all",
      },
      visible: false,
      labelClass: "activity__icon__circle--all",
      masks: {
        weekdays: "W",
      },
      selectedActivity: {
        title_zh: "",
        registration_url: "",
        speakers: [],
      },
      attributes: null,
      isOpenInfo: false,
      isSelectAll: true,
      iconArray: [
        {
          name: "all",
          active: true,
          text_zh: "總覽",
          text_en: "All",
          labelClass: "activity__icon__circle--all",
        },
        {
          name: "tour",
          active: true,
          text_zh: "導覽",
          text_en: "Guided Tour",
          labelClass: "activity__icon__circle--tour",
        },
        {
          name: "discussion",
          active: true,
          text_zh: "藝術家暨策展人座談",
          text_en: "Artists / Curator Talk",
          labelClass: "activity__icon__circle--discussion",
        },
        {
          name: "processing",
          active: true,
          text_zh: "Processing臺灣國際社群日",
          text_en: "PCD Taiwan",
          labelClass: "activity__icon__circle--processing",
        },
        {
          name: "touch",
          active: true,
          text_zh: "TouchDesigner新媒體互動集線器",
          text_en: "TouchDesigner: New Media Hub",
          labelClass: "activity__icon__circle--touch",
        },
        {
          name: "playground",
          active: true,
          text_zh: "Playaround工作坊：回顧與展望",
          text_en: "Playaround: Generation and Evolution",
          labelClass: "activity__icon__circle--playground",
        },
        // {
        //   name: "lab",
        //   active: true,
        //   text_zh: "FUTURE VISION LAB 展演",
        //   text_en: "FUTURE VISION LAB 2021",
        //   labelClass: "activity__icon__circle--lab",
        // },
      ],
      isSelectOct: true,
    };
  },
  methods: {
    getDate(ios) {
      const date = new Date(ios);
      let dt = date.getDate();
      if (dt < 10) {
        dt = "0" + dt;
      }
      return dt;
    },
    async selectActivity(newActivity) {
      newActivity = await getActivity(newActivity.customData.activity_id);
      const dt = this.parseActivityDateTime(
        newActivity.start_date,
        newActivity.end_date
      );
      newActivity.date = dt.date;
      newActivity.start_time = dt.start_time;
      newActivity.end_time = dt.end_time;
      this.selectedActivity = newActivity;
    },
    parseActivityDateTime(startDT, endDT) {
      let start_datetime = moment(startDT);
      let end_datetime = moment(endDT);
      return {
        date: start_datetime.utcOffset("+0800").format("YYYY/M/DD"),
        start_time: start_datetime.utcOffset("+0800").format("HH:mm"),
        end_time: end_datetime.utcOffset("+0800").format("HH:mm"),
      };
    },
    onChangeIcon(item) {
      this.value = { ...item };
      let select;
      let beSelectItem = { ...item };
      let icon = beSelectItem.name;
      if (!this.isSelectAll) {
        select = this.iconArray.find((val) => {
          if (val.active && val.name === icon) {
            return true;
          }
        });
        if (select !== undefined) {
          if (select.name === icon) {
            icon = "all";
          }
        }
      }
      // console.log(select,this.iconArray)
      this.iconArray.map((val) => {
        if (icon === "all") {
          val.active = true;
          this.isSelectAll = true;
          this.value = {
            name: "all",
            active: true,
            text_zh: "總覽",
            text_en: "All",
            labelClass: "activity__icon__circle--all",
          };
        } else if (icon === val.name) {
          val.active = true;
          this.isSelectAll = false;
        } else {
          val.active = false;
        }
      });
    },
    async updateActivities() {
      let activities = await getActivities();
      const categoryClassMap = {
        導覽: "label__title--tour",
        藝術家暨策展人座談: "label__title--discussion",
        Processing臺灣國際社群日: "label__title--processing",
        TouchDesigner新媒體互動集線器: "label__title--touch",
        "Playaround工作坊：回顧與展望": "label__title--playground",
        "Future Vision Lab展演": "label__title--fvl",
      };

      this.attributes = activities.map((a, index) => {
        // let date = moment(a.start_date);
        // let dateComponent = date.utcOffset('+0800').format('YYYY-MM-DD');
        // let timeComponent = date.utcOffset('+0800').format('HH:mm:ss');

        const dt = this.parseActivityDateTime(a.start_date, a.end_date);

        return {
          key: index,
          customData: {
            ...a,
            time: dt.start_time + "-" + dt.end_time,
            title: a.title_zh,
            class: categoryClassMap[a.category.name_zh],
          },
          dates: dt.date,
        };
      });
    },
    selectorToggle() {
      this.visible = !this.visible;
    },
    select(item) {
      this.value = item;
      this.onChangeIcon(item);
    },
    async moveToMonth(year, month) {
      const calendar = this.$refs.calendar;
      await calendar.move({ month, year }).then(console.log, console.error);
    },
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (this.$refs.labelSelector) {
        if (!this.$refs.labelSelector.contains(e.target)) {
          if (this.visible) {
            this.selectorToggle();
          }
        }
      }
    });
  },
  created() {
    this.updateActivities();
  },
  computed: {
    filteredActivities() {
      if (this.value.name === "all") return this.attributes;
      return this.attributes.filter(
        (a) => a.customData.category.name_zh === this.value.text_zh
      );
    },
    localeLang() {
      return this.$i18n.locale;
    }
  },
  setup() {},
};
</script>

<style lang="sass" scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap')
@import '@/assets/css/custom.scss'

$any: #fff
$tour: #BFB2A6
$discussion: #A1C1E4
$processing: #141414
$touch: #8D6141
$playground: #B3D6CD
$lab: #000AFF
$fvl: #397367

#activity
  min-height: 100vh
  width: 100%
  overflow-x: hidden

.bg
  // margin-top: 130px
  z-index: -1
  width: 100%
  position: fixed
  max-width: 1440px
  +rwd-md
    width: 150%
    left: 50%
    transform: translateX(-56%)

  &--wrap
    width: 100%
    // left: 50%
    position: relative
    top: auto
    +rwd-md
      width: 150%
      top: 292px

  &--sky
    position: relative
    padding-top: 82%
    width: 100%
    overflow: hidden
    max-width: 1440px
    // top: 50%
    // left: 50%
    // transform: translate(-50%,-54%)
    video
      width: 130%
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      object-fit: cover
//&--mask
  // top: 50%
  // left: 50%
  // transform: translate(-50%,-50%)
.activity
  cursor: pointer
  width: 100%

.activityBlock
  padding-top: 138px
  margin-right: auto
  margin-left: auto
  width: 100%
  max-width: 1195px
  min-height: 100%
  padding-bottom: 80px
  +rwd-md
    padding: 97px 20px 0 20px

.activity__month
  margin-bottom: 12px
  +rwd-md
    margin-bottom: 8px

.activity__text
  cursor: pointer
  display: inline
  font-size: 36px
  font-weight: 700
  margin-right: 22px
  color: #C4C4C4

  &--active
    color: #000

.activity__label
  display: flex
  align-items: flex-start
  margin-bottom: 2px
  flex-wrap: wrap

.activity__icon
  background-color: #F4F4F4
  padding: 8px
  border-radius: 100px
  margin-right: 12px
  margin-bottom: 8px
  display: inline-flex
  align-items: center
  opacity: 0.2
  cursor: pointer

  &--active
    opacity: 1

  &--all
    background-color: transparent
    opacity: 1

  &__circle
    width: 18px
    height: 18px
    border-radius: 100%
    margin-right: 8px
    +rwd-md
      margin-bottom: 0

    &--all
      display: none
      background-color: transparent

    &--tour
      background-color: $tour

    &--discussion
      background-color: $discussion

    &--processing
      background-color: $processing

    &--touch
      background-color: $touch

    &--playground
      background-color: $playground

    &--lab
      background-color: $lab

    &--fvl
      background-color: $fvl

  &__text
    font-weight: 500
    +rwd-md
      font-size: 14px

    &--all
      font-weight: 900

.day-label
  +rwd-md
    font-size: 18px

.date
  width: 100%
  padding: 6px
  +rwd-md
    padding: 12.5px

.label
  &__time
    font-family: 'Inter', Arial, Helvetica, sans-serif
    color: #6C6C6C
    font-size: 9px
    +rwd-md
      font-size: 12px

  &__title
    font-size: 10px
    padding: 7px 4px 7px 7px
    +rwd-md
      font-size: 12px
      padding: 10px

    &--any
      background-color: $any

    &--tour
      background-color: $tour

    &--discussion
      background-color: $discussion

    &--processing
      background-color: $processing
      color: white

    &--touch
      background-color: $touch
      color: white

    &--playground
      background-color: $playground

    &--lab
      background-color: $lab
      color: white

  &__text
    font-size: 10px

.pb-6
  padding-bottom: 6px

.info__block
  width: 100%
  height: 100%
  +rwd-md
    position: fixed
    top: 64px
    right: 0
    bottom: 0
    left: 0
    z-index: 1000
    height: calc(100% - 64px)

  &--none
    +rwd-md
      display: none

.info
  position: relative
  display: flex
  flex-direction: column
  min-height: 100%
  max-height: 100px
  background: #fff
  padding: 16px 11px 16px 16px
  border: 1px solid #000
  overflow-y: scroll
  overflow-x: hidden
  font-weight: 500
  margin-bottom: 6px


  &--none
    background: rgba(255, 255, 255, 0.2)
    border: 1px solid #C2C2C2
    overflow: hidden
    +rwd-md
      display: none

  &::-webkit-scrollbar-track
    // background-color: blue
    border-right: 16px solid #fff
    background-clip: padding-box
  // width: 20px

  &::-webkit-scrollbar
    width: 20px
    background-color: #fff

  &::-webkit-scrollbar-thumb
    background-color: #D1D1D1
    border: 16px solid #fff
    border-left-width: 0
    background-clip: padding-box

  &::-webkit-scrollbar-corner
    background-color: transparent

  &__sign
    text-align: center
    color: #797979
    margin: 47px auto auto

  &__img
    border: solid 1px #000
    // position: relative
    // padding-top: 49.6268%
    // width: 100%
    // overflow: hidden
    cursor: pointer
    position: relative
    box-sizing: border-box
    background-repeat: no-repeat
    background-position: center
    background-size: cover

    img
      padding-top: 52%
      width: 100%

  &__title
    font-size: 24px
    font-weight: 700
    font-family: "Noto Sans TC", Arial, Helvetica, sans-serif
    margin: 8px 0

  &__item
    font-weight: 500
    font-family: "Noto Sans TC", Arial, Helvetica, sans-serif
    margin-bottom: 8px

  &__btn
    cursor: pointer
    border-radius: 2px
    margin: 25px 0 4px
    width: 50%
    display: inline-block
    background: #000
    text-align: center
    color: #fff
    padding: 12px 0
    font-weight: 700
    text-decoration: none

    &--left
      margin-right: 3px

    &--right
      margin-left: 3px

    &--back
      width: 100%
      text-align: left
      padding-left: 14px
      margin-bottom: 11px

      img
        padding-right: 8px
    
    &--full
      width: 100%
      text-align: center
      margin: 0

.info__artist
  &__img
    position: relative
    width: 70px
    height: 70px
    overflow: hidden
    margin-right: 10px

    img
      width: 100%
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      object-fit: cover

  &__name
    font-weight: 500
    font-size: 12px

  &__text
    padding-top: 10px
    font-size: 12px

.artistModal__hr
  width: 100%
  height: 1px
  border-bottom: solid 1px #000
  margin: 16px 0

.custom-calendar
  +rwd-md
    padding-right: 0 !important

.artist__close
  position: absolute
  right: -36px
  top: -1px
  z-index: 1
  height: 36px
  width: 36px
  display: none
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: #000
  cursor: pointer
  +rwd-md
    display: flex
    top: 9px
    right: 9px
    height: 60px
    width: 60px

  &::before
    transform: rotate(45deg)
    top: 48%

  &::after
    transform: rotate(-45deg)
    top: 48%

  &:hover.artist__close::before
    width: 110%
    left: -2px
  // +rwd-md
  //   width: 40%
  //   left: 20px
  &:hover.artist__close::after
    width: 110%
    left: -2px
// +rwd-md
//   width: 36%
//   left: 20px

.artist__close::before,
.artist__close::after
  transition: 0.1s
  position: absolute
  content: ''
  width: 70%
  height: 2px
  background-color: #fff
  top: 48%
  left: 5px
  +rwd-md
    width: 36%
    left: 20px


.activity__select
  margin-bottom: 7px
  z-index: 1000
  width: 100%
  position: relative

  .selector
    cursor: pointer
    background: rgba(246, 246, 246, 1)
    position: relative
    z-index: 1

  .arrow
    position: absolute
    right: 17px
    top: 50%
    width: 19px
    transform: translateY(-50%)

  .expanded
    transform: rotateZ(180deg) translateY(8px)

  .label
    display: flex
    padding: 17px
    font-size: 14px
    // font-weight: 900
    color: #000

  ul
    width: 100%
    list-style-type: none
    padding: 0
    margin: 0
    font-size: 16px
    position: absolute
    z-index: 1000
    background: rgba(237, 237, 237, 1)

    .current
      background: #d7d7d7

    li
      padding: 15px 17px
      color: #000
      font-weight: 500
  // &:hover
  //   // color: white
  //   background: #d7d7d7
  .hidden
    visibility: hidden

  .visible
    visibility: visible
</style>
