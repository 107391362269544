<template lang="pug">
.moreinfo(
  ref="moreBlock"
  :class="{'moreinfo--active': !isMore}"
)
  slot(name="moreBlock")
  .morebtn(:class="{'morebtn--hide': !isMore}" @click="isMore = false")
    .morebtn__text.flex-center
      .d-inline {{ $t('shared.showMore') }}
      img.morebtn__img(:src="require(`@/assets/images/more.svg`)")
</template>

<script>
export default {
  name: "MoreInfo",
  components: {},
  data() {
    return {
      isMore: true,
      isCheck: false,
    };
  },
  methods: {
    checkMore(check) {
      if (!this.isCheck) {
        const height = this.$refs.moreBlock.clientHeight;
        this.isMore = height >= 230;
      }
      if (check) {
        this.isCheck = check;
      }
    },
  },
  mounted() {
    this.checkMore();
  },
  updated: function () {
    this.$nextTick(() => {
      this.checkMore(true);
    });
  },
  setup() {},
};
</script>

<style lang="sass" scoped>
*
  transition: max-height 1s linear

.moreinfo
  position: relative
  width: 100%
  max-height: 230px
  overflow: hidden
  padding: 16px
  margin-bottom: 7px

  &--active
    height: auto
    max-height: 999px
    overflow: auto

.morebtn
  position: absolute
  display: flex
  justify-content: center
  align-items: flex-end
  top: 0
  right: 0
  width: 100%
  height: 100%
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 55%, #FFFFFF 92%)

  &--hide
    display: none

  &__text
    font-size: 12px
    cursor: pointer
    align-self: flex-end

  &__img
    width: 7px
    height: auto
    margin-left: 5px
</style>
